<template>
  <b-overlay
    :show="showOverlay"
    opacity="0.25"
    blur="1rem"
    rounded="md"
    variant="secondary"
  >
    <b-card-code>
      <!-- modal -->
      <b-modal ref="my-modal" hide-footer title="Create New Group" size="lg">
        <validation-observer
          ref="create-new-system-group"
          #default="{ invalid }"
        >
          <b-form class="mt-2" @submit.prevent="handleNewSystemGroupSubmit">
            <!-- Title -->
            <b-col>
              <b-form-group label="Name">
                <template slot="label">
                  Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="createNewSystemGroup.title"
                    :state="errors.length > 0 ? false : null"
                    name="title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Description -->
            <b-col>
              <b-form-group label="Description">
                <validation-provider #default="{ errors }" name="Description">
                  <!-- <b-form-input
                  id="description"
                  v-model="createNewSystemGroup.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                /> -->
                  <b-form-textarea
                    id="description"
                    v-model="createNewSystemGroup.description"
                    debounce="500"
                    rows="3"
                    max-rows="5"
                    :state="errors.length > 0 ? false : null"
                    name="description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="d-flex justify-content-end">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
              >
                <b-spinner
                  v-if="showOverlay"
                  label="Loading..."
                  small
                  class="mr-50"
                />
                <feather-icon v-else icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Create</span>
              </b-button>
            </b-col>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- form -->
      <validation-observer ref="AssetEditForm" #default="{ invalid }">
        <b-form class="auth-login-form" @submit.prevent="onUpdateClick">
          <!-- Asset Name -->
          <b-form-group label="Asset Name">
            <template slot="label">
              Asset Name <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Asset Name"
              rules="required"
            >
              <b-form-input
                id="asset-name"
                v-model="asset_name"
                :state="errors.length > 0 ? false : null"
                name="asset-name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Asset Location -->
          <b-form-group label="Asset Location">
            <template slot="label">
              Asset Location <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Asset Location"
              rules="required"
            >
              <b-form-input
                id="asset-location"
                v-model="asset_location"
                :state="errors.length > 0 ? false : null"
                name="asset-location"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Asset Value -->
          <b-form-group label="Asset Value">
            <template slot="label">
              Asset Value <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Asset Value"
              rules="required"
            >
              <b-form-select
                v-model="asset_value"
                :options="asset_value_options"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Asset Type-->
          <b-form-group label="Asset Category">
            <template slot="label">
              Asset Category <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Asset Category"
              rules="required"
            >
              <b-form-select
                selected
                v-model="asset_type"
                :options="assetTypes"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Asset Owner-->
          <b-form-group label="Asset Owner">
            <template slot="label">
              Asset Owner <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Asset Owner"
              rules="required"
            >
              <b-form-select
                v-model="asset_owner"
                :options="assetOwners"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Asset Classification-->
          <b-form-group label="Classification">
            <template slot="label">
              Classification <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Asset Classification"
              rules="required"
            >
              <b-form-select
                v-model="asset_classification"
                :options="assetClassifications"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- System Groups -->
          <b-form-group label="System Groups">
            <b-row>
              <b-col cols="8">
                <vue-autosuggest
                  :suggestions="systemGroupSuggestions"
                  :limit="5"
                  v-model="currentSystemGroup"
                  id="autosuggest__input"
                  :input-props="systemGroupInputProps"
                  @input="getSystemGroupsBySearchPhraseAndSetData"
                  @selected="onSystemGroupSelected"
                  :get-suggestion-value="getSystemGroupSuggestionValue"
                >
                  <template slot-scope="{ suggestion }">
                    <span style="{ display: 'flex', color: 'navyblue'}">{{
                      suggestion.item.title
                    }}</span>
                  </template>
                </vue-autosuggest>
                <div
                  class="d-flex flex-wrap align-items-center justify-content-start mt-75"
                >
                  <template v-for="(value, i) in selectedSystemGroups">
                    <b-badge :key="i" variant="light-primary" class="mr-1">
                      <span>{{ value.title }}</span>
                      <feather-icon
                        @click="removeSystemGroup(value.title)"
                        icon="XCircleIcon"
                        class="cursor-pointer ml-50 text-danger"
                      />
                    </b-badge>
                  </template>
                </div>
              </b-col>
              <b-col>
                <b-button @click="showModal" variant="primary"
                  ><feather-icon
                    icon="PlusIcon"
                    class="cursor-pointer mr-50"
                  />Create new group</b-button
                >
              </b-col>
            </b-row>
          </b-form-group>

          <!-- Tags -->
          <b-form-group label="Tags">
            <vue-autosuggest
              :suggestions="tagOptions"
              :limit="5"
              v-model="tagName"
              id="autosuggest__input"
              :input-props="tagInputProps"
              @input="getAssetTagsBySearchPhraseAndSetData"
              @selected="onTagSelected"
              :get-suggestion-value="getTagSuggestionValue"
            >
              <template slot-scope="{ suggestion }">
                <span style="{ display: 'flex', color: 'navyblue'}">{{
                  suggestion.item.title
                }}</span>
              </template>
            </vue-autosuggest>
            <div
              class="d-flex flex-wrap align-items-center justify-content-start mt-75"
            >
              <template v-for="(value, i) in selectedTags">
                <b-badge :key="i" variant="light-primary" class="mr-1">
                  <span>{{ value.tag_title }}</span>
                  <feather-icon
                    @click="removeTag(value.tag_title)"
                    icon="XCircleIcon"
                    class="cursor-pointer ml-50 text-danger"
                  />
                </b-badge>
              </template>
            </div>
          </b-form-group>

          <!-- <b-row class="my-1">
            
            <b-form-checkbox
              v-model="asset_integrity"
              value="true"
              class="custom-control-primary ml-1"
              name="integrity-radio-group"
              >Asset Integrity</b-form-checkbox
            >

            
            <b-form-checkbox
              v-model="asset_confidentiality"
              name="confidentiality-radio-group"
              value="true"
              class="custom-control-primary ml-2"
              >Asset Confidentiality</b-form-checkbox
            >

           
            <b-form-checkbox
              id="availability-radio-group"
              v-model="asset_availability"
              name="availability-radio-group"
              value="true"
              class="custom-control-primary ml-2"
              >Asset Availability</b-form-checkbox
            >
          </b-row> -->

          <b-button
            type="submit"
            variant="success"
            :disabled="invalid"
            class="mt-2"
          >
            <feather-icon icon="CheckIcon" class="cursor-pointer mr-50" />
            Update
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
  </b-overlay>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { VueAutosuggest } from "vue-autosuggest";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BFormFile,
  BBadge,
  BModal,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AssetMixins from "../../mixins/AssetMixins";
import TagMixins from "@/mixins/TagMixins";

export default {
  name: "AssetEditPage",
  components: {
    ToastificationContent,
    BCardCode,
    BFormRadio,
    BFormRadioGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    VueAutosuggest,
    BBadge,
    BModal,
    BOverlay,
    BSpinner,
  },
  data() {
    return {
      showOverlay: false,
      asset_name: "",
      asset_location: "",
      asset_value: null,
      asset_value_options: [
        { text: "--Select Asset Value--", value: null },
        { text: "Very High", value: 4 },
        { text: "High", value: 3 },
        { text: "Medium", value: 2 },
        { text: "Low", value: 1 },
      ],

      asset_integrity: true,
      integrity_options: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],

      asset_availability: true,
      availability_options: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],

      asset_confidentiality: false,
      confidentiality_options: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],

      asset_type: null,
      assetTypes: [{ value: null, text: "--Select Asset Category--" }],

      asset_owner: null,
      assetOwners: [{ value: null, text: "--Select Asset Owner--" }],

      asset_classification: null,
      assetClassifications: [
        { value: null, text: "--Select Classification--" },
      ],

      currentSystemGroup: null,
      systemGroupSuggestions: [],
      selectedSystemGroups: [],
      systemGroupInputProps: {
        class: "form-control",
        placeholder: "Search & Select System Groups..",
      },

      selectedTags: [],
      tagOptions: [],
      tagName: "",
      tagInputProps: {
        class: "form-control",
        placeholder: "Search & Select Tags..",
      },

      createNewSystemGroup: {
        title: "",
        description: "",
      },
    };
  },
  mounted: function () {
    this.load();
  },
  mixins: [AssetMixins, TagMixins],
  methods: {
    showModal() {
      this.createNewSystemGroup = { title: "", description: "" };
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    load() {
      this.getAllAssetTypesAndSetOptions();
      this.getAllAssetClassificationsAndSetOptions();
      this.getAllAssetOwnersAndSetOptions();
      this.getAssetDetailsAndSetData(this.$route.params.id);
    },

    getAllAssetTypesAndSetOptions() {
      this.getAssetTypes()
        .then((res) => {
          let assettypes = res.data.data;
          for (let i = 0; i < assettypes.length; i++) {
            this.assetTypes.push({
              value: assettypes[i]._id,
              text: assettypes[i].name,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    getAllAssetClassificationsAndSetOptions() {
      this.getAssetClassifications()
        .then((res) => {
          let classifications = res.data.data;
          for (let i = 0; i < classifications.length; i++) {
            this.assetClassifications.push({
              value: classifications[i]._id,
              text: classifications[i].title,
            });
          }
        })
        .catch((err) => {
          consol.log(err);
        });
    },

    getAllAssetOwnersAndSetOptions() {
      this.getAssetOwners()
        .then((res) => {
          let owners = res.data.data;
          for (let i = 0; i < owners.length; i++) {
            this.assetOwners.push({
              value: owners[i].user_id,
              text: `${owners[i].firstname} ${owners[i].lastname}`,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onUpdateClick() {
      this.showOverlay = true;

      const bodyFormData = new FormData();
      var params = new URLSearchParams();
      bodyFormData.append("asset_category_id", this.asset_type);
      bodyFormData.append("asset_owner_id", this.asset_owner);
      bodyFormData.append("asset_classification_id", this.asset_classification);
      bodyFormData.append("asset_location[]", this.asset_location);
      bodyFormData.append("asset_name", this.asset_name);
      bodyFormData.append("asset_value", this.asset_value);
      bodyFormData.append("asset_integrity", this.asset_integrity ? 1 : 0);
      bodyFormData.append(
        "asset_confidentiality",
        this.asset_confidentiality ? 1 : 0
      );
      bodyFormData.append(
        "asset_availability",
        this.asset_availability ? 1 : 0
      );
      this.selectedSystemGroups.map((group, i) => {
        bodyFormData.append(`system_groups[${i}]`, group._id);
      });
      this.selectedTags.map((tag, i) => {
        bodyFormData.append(`tags[${i}][is_new]`, `${tag.is_new ? 1 : 0}`);
        if (tag.is_new === true) {
          bodyFormData.append(`tags[${i}][tag_title]`, `${tag.tag_title}`);
        }
        bodyFormData.append(
          `tags[${i}][tag_id]`,
          `${tag.tag_id ? tag.tag_id : ""}`
        );
      });
      const options = {
        method: "POST",
        // headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/assets/${this.$route.params.id}`,
        data: bodyFormData,
      };
      this.$http(options)
        .then(() => {
          this.$router.push({ name: "assets" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Asset Updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Asset not Updated",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          console.log("err", err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getAssetTypeAndSetData(id) {
      this.getAssetType(id)
        .then((res) => {
          console.log("Asset Category Resp", res);
          this.asset_type = res.data.data.asset._id;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    getAssetClassification(id) {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_BASEURL}/asset-classifications/` + id,
      };
      this.$http(options)
        .then((res) => {
          this.asset_classification = res.data.data._id;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    getAssetDetailsAndSetData() {
      this.showOverlay = true;
      this.getAssetDetails(this.$route.params.id)
        .then((res) => {
          console.log(res);
          this.asset_name = res.data.data.asset_name;
          this.asset_value = res.data.data.asset_value;
          this.asset_integrity = res.data.data.asset_integrity;
          this.asset_confidentiality = res.data.data.asset_confidentiality;
          this.asset_type = res.data.data.category._id;
          this.asset_availability = res.data.data.asset_availability;
          this.asset_location = res.data.data.asset_location[0];
          if (
            res.data.data.owner &&
            res.data.data.owner != null &&
            res.data.data.owner.user_id &&
            res.data.data.owner.user_id != null
          ) {
            this.asset_owner = res.data.data.owner.user_id;
          }

          res.data.data.tags.map((tag) => {
            this.selectedTags.push({
              tag_id: tag._id,
              tag_title: tag.title,
              is_new: false,
            });
          });

          this.selectedSystemGroups = res.data.data.system_groups;
          // res.data.data.system_groups.map((group) => {
          //   this.selectedSystemGroups.push({
          //     group_id: group._id,
          //     group_title: group.title,
          //     is_new: false,
          //   });
          // });
          // this.getAssetTypeAndSetData(res.data.data.asset_category_id);
          this.getAssetClassification(res.data.data.asset_classification_id);
        })
        .catch((err) => {
          this.handleError(err);
          console.log("err", err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getAssetTagsBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getAssetTagsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.tagOptions = [];
              const newTags = res.data.data.data;
              if (newTags.length == 0) {
                newTags.push({ title: searchPhrase, is_new: true });
              }
              this.tagOptions.push({ name: "tags", data: newTags });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.tagOptions = [];
      }
    },

    onTagSelected(val) {
      console.log("val", val);
      if (val) {
        const selectedVal = val.item;
        const tagIndex = this.selectedTags.findIndex(
          (tag) => tag.tag_title === selectedVal.title
        );
        if (tagIndex === -1) {
          this.tagName = "";
          this.selectedTags.push({
            tag_id: selectedVal._id,
            tag_title: selectedVal.title,
            is_new: selectedVal._id ? false : true,
          });
        }
      }
    },

    removeTag(tagName) {
      let newSelectedTags = this.selectedTags.filter(
        (tag) => tag.tag_title !== tagName
      );
      this.selectedTags = newSelectedTags;
    },

    getTagSuggestionValue(suggestion) {
      return suggestion.item.title;
    },

    // --------System Group-------

    getSystemGroupsBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getSystemGroupsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.systemGroupSuggestions = [];
              const newSystemGroups = res.data.data.data;
              if (newSystemGroups.length !== 0) {
                this.systemGroupSuggestions.push({
                  name: "systemGroups",
                  data: newSystemGroups,
                });
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.tagOptions = [];
      }
    },

    handleNewSystemGroupSubmit() {
      this.addNewSystemGroup(this.createNewSystemGroup)
        .then((res) => {
          this.hideModal();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "A New System Group has been created.",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.selectedSystemGroups.push(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          this.hideModal();
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    onSystemGroupSelected(val) {
      if (val) {
        let selectedVal = val.item;
        console.log("VAL", selectedVal);
        const groupIndex = this.selectedSystemGroups.findIndex(
          (group) => group.title === selectedVal.title
        );
        if (groupIndex === -1) {
          this.currentSystemGroup = "";
          // selectedVal['group_title'] = selectedVal.title
          this.selectedSystemGroups.push(selectedVal);
        }
      }
    },
    removeSystemGroup(title) {
      let newSelectedSystemGroups = this.selectedSystemGroups.filter(
        (g) => g.title !== title
      );
      this.selectedSystemGroups = newSelectedSystemGroups;
    },
    getSystemGroupSuggestionValue(suggestion) {
      return suggestion.item.title;
    },
  },
};
</script>

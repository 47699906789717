var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showOverlay,"opacity":"0.25","blur":"1rem","rounded":"md","variant":"secondary"}},[_c('b-card-code',[_c('b-modal',{ref:"my-modal",attrs:{"hide-footer":"","title":"Create New Group","size":"lg"}},[_c('validation-observer',{ref:"create-new-system-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleNewSystemGroupSubmit.apply(null, arguments)}}},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Name"}},[_c('template',{slot:"label"},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"title"},model:{value:(_vm.createNewSystemGroup.title),callback:function ($$v) {_vm.$set(_vm.createNewSystemGroup, "title", $$v)},expression:"createNewSystemGroup.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","debounce":"500","rows":"3","max-rows":"5","state":errors.length > 0 ? false : null,"name":"description"},model:{value:(_vm.createNewSystemGroup.description),callback:function ($$v) {_vm.$set(_vm.createNewSystemGroup, "description", $$v)},expression:"createNewSystemGroup.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[(_vm.showOverlay)?_c('b-spinner',{staticClass:"mr-50",attrs:{"label":"Loading...","small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Create")])],1)],1)],1)]}}])})],1),_c('validation-observer',{ref:"AssetEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onUpdateClick.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Asset Name"}},[_c('template',{slot:"label"},[_vm._v(" Asset Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Asset Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"asset-name","state":errors.length > 0 ? false : null,"name":"asset-name"},model:{value:(_vm.asset_name),callback:function ($$v) {_vm.asset_name=$$v},expression:"asset_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Asset Location"}},[_c('template',{slot:"label"},[_vm._v(" Asset Location "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Asset Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"asset-location","state":errors.length > 0 ? false : null,"name":"asset-location"},model:{value:(_vm.asset_location),callback:function ($$v) {_vm.asset_location=$$v},expression:"asset_location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Asset Value"}},[_c('template',{slot:"label"},[_vm._v(" Asset Value "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Asset Value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.asset_value_options,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_value),callback:function ($$v) {_vm.asset_value=$$v},expression:"asset_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Asset Category"}},[_c('template',{slot:"label"},[_vm._v(" Asset Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Asset Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"selected":"","options":_vm.assetTypes,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_type),callback:function ($$v) {_vm.asset_type=$$v},expression:"asset_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Asset Owner"}},[_c('template',{slot:"label"},[_vm._v(" Asset Owner "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Asset Owner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assetOwners,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_owner),callback:function ($$v) {_vm.asset_owner=$$v},expression:"asset_owner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Classification"}},[_c('template',{slot:"label"},[_vm._v(" Classification "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Asset Classification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assetClassifications,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_classification),callback:function ($$v) {_vm.asset_classification=$$v},expression:"asset_classification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"System Groups"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.systemGroupSuggestions,"limit":5,"id":"autosuggest__input","input-props":_vm.systemGroupInputProps,"get-suggestion-value":_vm.getSystemGroupSuggestionValue},on:{"input":_vm.getSystemGroupsBySearchPhraseAndSetData,"selected":_vm.onSystemGroupSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.title))])]}}],null,true),model:{value:(_vm.currentSystemGroup),callback:function ($$v) {_vm.currentSystemGroup=$$v},expression:"currentSystemGroup"}}),_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-start mt-75"},[_vm._l((_vm.selectedSystemGroups),function(value,i){return [_c('b-badge',{key:i,staticClass:"mr-1",attrs:{"variant":"light-primary"}},[_c('span',[_vm._v(_vm._s(value.title))]),_c('feather-icon',{staticClass:"cursor-pointer ml-50 text-danger",attrs:{"icon":"XCircleIcon"},on:{"click":function($event){return _vm.removeSystemGroup(value.title)}}})],1)]})],2)],1),_c('b-col',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.showModal}},[_c('feather-icon',{staticClass:"cursor-pointer mr-50",attrs:{"icon":"PlusIcon"}}),_vm._v("Create new group")],1)],1)],1)],1),_c('b-form-group',{attrs:{"label":"Tags"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.tagOptions,"limit":5,"id":"autosuggest__input","input-props":_vm.tagInputProps,"get-suggestion-value":_vm.getTagSuggestionValue},on:{"input":_vm.getAssetTagsBySearchPhraseAndSetData,"selected":_vm.onTagSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.title))])]}}],null,true),model:{value:(_vm.tagName),callback:function ($$v) {_vm.tagName=$$v},expression:"tagName"}}),_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-start mt-75"},[_vm._l((_vm.selectedTags),function(value,i){return [_c('b-badge',{key:i,staticClass:"mr-1",attrs:{"variant":"light-primary"}},[_c('span',[_vm._v(_vm._s(value.tag_title))]),_c('feather-icon',{staticClass:"cursor-pointer ml-50 text-danger",attrs:{"icon":"XCircleIcon"},on:{"click":function($event){return _vm.removeTag(value.tag_title)}}})],1)]})],2)],1),_c('b-button',{staticClass:"mt-2",attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"cursor-pointer mr-50",attrs:{"icon":"CheckIcon"}}),_vm._v(" Update ")],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }